import PageContainer from 'components/shared/generic/pageContainer/presentational/PageContainer';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import useTranslate from 'lib/languages/useTranslate';
import { MORE_OPEN_BANKING_SERVICES, MORE_OPEN_BANKING_SERVICES_DELETE } from 'constants/shared/routes';
import { useLocation, useHistory } from 'react-router-dom';
import Typography from 'components/shared/generic/typography/presentational/Typography';
import dayjs from 'dayjs';
import Button from 'components/shared/generic/button/presentational/Button';
import DateIcon from '_content/images/icons/more/date.svg';
import RevokedIcon from 'lib/_content/images/icons/revokedIcon.svg';
import CrossIcon from '_content/images/icons/more/cross.svg';
import DetailRow from 'components/shared/generic/detailRow/DetailRow';
import { ConsentSchemeName, ConsentStatus, IAccount } from 'lib/type';
import { formatSortCode } from 'lib/utils';
import { useSelector } from 'react-redux';
import { getAccount } from 'lib/selectors';
import ConsentPermissions from './components/ConsentPermissions';

const ConsentManagementDetails = () => {
    const history = useHistory();
    const translate = useTranslate('consent');
    const location = useLocation();
    const account: IAccount = useSelector(getAccount);

    const { consent } = location.state || {};

    if (!consent) return history.push(MORE_OPEN_BANKING_SERVICES);

    const paymentAccount = consent?.PaymentInformation?.CreditorAccount ?? consent?.PaymentInformation?.DebtorAccount;

        
    const formattedFirstPaymentDate = consent?.PaymentInformation?.FirstPaymentDateTime
    ? dayjs(consent.PaymentInformation.FirstPaymentDateTime).format('DD/MM/YYYY') 
    : undefined;

    const formattedRevokedDate = consent?.ConsentRevoked
    ? dayjs(consent?.ConsentRevoked).format('DD/MM/YYYY') 
    : undefined;

    return (
        <PageContainer className="more-account-details-container">
            <SectionTitle
                title={translate('detailsTitleText')}
                backRoute={MORE_OPEN_BANKING_SERVICES}
            />
            <div className='consent-details-container'>
                <div className="consent-details-header">
                    <div className="consent-details-header-left">
                        <Typography tag="h1">{consent?.TppName}</Typography>
                        <DetailRow
                            label={translate('bankNumber')}
                            value={account?.bankAccountNo}
                        />
                        <DetailRow
                            label={translate('sortCode')}
                            value={formatSortCode(account?.sortCode)}
                        />
                    </div>
                </div>
                <div className="consent-details-content">
                    {!!consent?.Permissions?.length && <ConsentPermissions permissions={consent?.Permissions} />}
                    <DetailRow
                        label={translate('paymentDetails.identification')}
                        value={paymentAccount?.Identification}
                    />
                    <DetailRow label={translate('paymentDetails.payee')} value={paymentAccount?.Name} />
                    {paymentAccount?.SchemeName === ConsentSchemeName.SORT_CODE_ACCOUNT_NUMBER && (
                        <>
                            <DetailRow
                                label={translate('paymentDetails.payeeAccount')}
                                value={paymentAccount?.Identification.substring(6)}
                            />
                            <DetailRow
                                label={translate('paymentDetails.payeeSortcode')}
                                value={formatSortCode(paymentAccount?.Identification.substring(0, 6))}
                            />
                        </>
                    )}
                    <DetailRow
                        label={translate('paymentDetails.amount')}
                        value={consent?.PaymentInformation?.InstructedAmount?.Amount}
                    />
                    <DetailRow
                        label={translate('paymentDetails.currency')}
                        value={consent?.PaymentInformation?.InstructedAmount?.Currency}
                    />
                    <DetailRow
                        label={translate('paymentDetails.firstPaymentDateTime')}
                        value={formattedFirstPaymentDate}
                    />
                    <DetailRow
                        label={translate('paymentDetails.firstPaymentAmount')}
                        value={consent?.PaymentInformation?.FirstPaymentAmount?.Amount}
                    />
                    <DetailRow
                        label={translate('paymentDetails.firstPaymentCurrency')}
                        value={consent?.PaymentInformation?.FirstPaymentAmount?.Currency}
                    />
                    <DetailRow
                        label={translate('paymentDetails.frequency')}
                        value={consent?.PaymentInformation?.Frequency}
                    />
                    <DetailRow
                        label={translate('paymentDetails.reference')}
                        value={consent?.PaymentInformation?.Reference}
                    />
                </div>
                <div className="consent-details-bottom">
                    <DetailRow
                        label={translate('createdAt')}
                        value={dayjs(consent?.ConsentRequestCreated).format('DD/MM/YYYY')}
                        icon={DateIcon}
                    />
                    <DetailRow
                        label={translate('expiresAt')}
                        value={dayjs(consent?.ConsentExpiry).format('DD/MM/YYYY')}
                        icon={CrossIcon}
                    />
                    <DetailRow
                        label={translate('revokedAt')}
                        value={formattedRevokedDate}
                        icon={RevokedIcon}
                        className="consent-revoked-date"
                    />
                </div>
                {consent?.ConsentStatus === ConsentStatus.AUTHORISED && <Button onClick={() => history.push(MORE_OPEN_BANKING_SERVICES_DELETE, { consent })} className='consent-details-cancel-button'>{translate("buttonCancel")}</Button>}
            </div>
        </PageContainer>
    );
};

export default ConsentManagementDetails;
