import clsx from 'clsx';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getTheme } from 'selectors/darkMode';
import useTranslate from 'lib/languages/useTranslate';

interface Tab {
    tabTitle: string;
    component: React.ComponentType<any>;
    props?: any;
}

interface TabsProps {
    tabsData: Record<string, Tab>;
    subTabs?: string[]
}

const Tabs = ({ tabsData, subTabs }: TabsProps) => {
    const translate = useTranslate('subTab');
    const tabKeys = Object.keys(tabsData);
    const [activeTab, setActiveTab] = useState(tabKeys[0]);
    const [activeSubTab, setActiveSubTab] = useState(subTabs?.[0]);

    const isDarkModeEnabled = useSelector(getTheme);

    const getButtonClass = (isActive: boolean) => clsx("tab-button", { 'active': isActive, 'dark-mode': isDarkModeEnabled });

    const TabComponent = tabsData[activeTab].component;

    return (
        <div className="tab">
            <div className="tab-buttons">
                {tabKeys.map(tabKey => (
                    <button
                        key={tabKey}
                        className={getButtonClass(activeTab === tabKey)}
                        onClick={() => setActiveTab(tabKey)}
                    >
                        {tabsData[tabKey].tabTitle}
                    </button>
                ))}
            </div>
            {subTabs?.length && (
                <div className="sub-tab-buttons">
                    {subTabs?.map(sub => (
                        <button
                            key={sub}
                            className={getButtonClass(activeSubTab === sub)}
                            onClick={() => setActiveSubTab(sub)}
                        >
                            {translate(sub)}
                        </button>
                    ))}
                </div>
            )}

            <div className="tab-content">
                <TabComponent activeSubTab={activeSubTab} {...tabsData[activeTab].props}/>
            </div>
        </div>
    );
};

export default Tabs;
