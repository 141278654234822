import Typography from '../typography/presentational/Typography';

interface DetailRowProps {
    label: string;
    value?: string;
    className?: string;
    icon?: string
}

const DetailRow = ({ label, value, className, icon }: DetailRowProps) => {
    if (!value) return null;
    return (
        <div className='detail-row'>
            {!!icon && <img src={icon} className='detail-row-icon' alt='detail-icon' />}
            <Typography className={className} tag="p">
                <strong>{label}:</strong> {value}
            </Typography>
        </div>
    );
};

export default DetailRow;