import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import clsx from 'clsx';

import Button from 'components/shared/generic/button/presentational/Button';
import Typography from 'components/shared/generic/typography/presentational/Typography';
import useTranslate from 'lib/languages/useTranslate';
import { getTheme } from 'selectors/darkMode';
import { isEmpty } from 'lib/utils';
import { hasExpired } from 'lib/utils/customDate';
import { MORE_OPEN_BANKING_SERVICES_DETAILS } from 'constants/shared/routes';
import { Consent, ConsentStatus, SubTab } from 'lib/type';
import DetailRow from 'components/shared/generic/detailRow/DetailRow';

interface ConsentAccountsProps {
    consentData: Consent[];
    activeSubTab: SubTab;
}

const ConsentList = ({ consentData, activeSubTab }: ConsentAccountsProps) => {
    const history = useHistory();
    const translate = useTranslate('consent');
    const isDarkModeEnabled = useSelector(getTheme);

    const handleDetails = consent => {
        history.push(MORE_OPEN_BANKING_SERVICES_DETAILS, { consent });
    };

    const consentFilteredData= consentData?.filter(consent => {
        const status = consent.ConsentStatus as ConsentStatus;

        const isExpired = status !== ConsentStatus.AUTHORISED;

        const isMatchingSubTab = isExpired
            ? activeSubTab === SubTab.HISTORY
            : activeSubTab !== SubTab.HISTORY;

        return isMatchingSubTab;
    })?.sort((a, b) => dayjs(b.ConsentRequestCreated).diff(dayjs(a.ConsentRequestCreated)));

    const getConsentStatusText = (status: string) => {
        switch (status) {
            case ConsentStatus.AUTHORISED:
                return translate('consentStatus.authorised');
            case ConsentStatus.REVOKED:
                return translate('consentStatus.revoked');
            case ConsentStatus.EXPIRED:
                return translate('consentStatus.expired');
            default:
                return '';
        }
    }

    if (isEmpty(consentFilteredData)) {
        return <Typography className="data-check-no-data">{translate('noDataMessage')}</Typography>;
    }

    return (
        <div
            className={clsx('active-consent-container', activeSubTab, {
                'dark-mode': isDarkModeEnabled,
            })}
        >
            {consentFilteredData.map(consent => {
                
                const {
                    ConsentId,
                    TppName,
                    ConsentedAccounts,
                    PaymentInformation,
                    ConsentRequestCreated,
                    ConsentExpiry,
                    ConsentStatus,
                } = consent || {};

                return (
                    <div key={ConsentId} className="active-consent-inner-container">
                        <div className="active-consent-left">
                            <Typography tag="h1">{TppName}</Typography>
                            <Typography tag="h2">
                                {translate('activeAccounts', { count: ConsentedAccounts.length })}
                            </Typography>
                            <DetailRow
                                label={translate('paymentDetails.amount')}
                                value={PaymentInformation?.InstructedAmount?.Amount}
                            />
                            <DetailRow
                                label={translate('paymentDetails.amount')}
                                value={PaymentInformation?.FirstPaymentAmount?.Amount}
                            />
                            <DetailRow
                                label={translate('paymentDetails.currency')}
                                value={PaymentInformation?.InstructedAmount?.Currency}
                            />
                            <DetailRow
                                label={translate('paymentDetails.currency')}
                                value={PaymentInformation?.FirstPaymentAmount?.Currency}
                            />
                            <DetailRow
                                label={translate('createdAt')}
                                value={dayjs(ConsentRequestCreated).format('DD/MM/YYYY')}
                            />
                            <DetailRow
                                label={translate('expiresAt')}
                                value={dayjs(ConsentExpiry).format('DD/MM/YYYY')}
                            />
                        </div>
                        <div className="active-consent-right">
                            <Button className={`active-consent-button ${activeSubTab}`}>
                                {getConsentStatusText(ConsentStatus)}
                            </Button>
                            <Button
                                disabled={hasExpired(ConsentExpiry)}
                                onClick={() => handleDetails(consent)}
                                className="details-consent-button"
                            >
                                {translate('manage')}
                            </Button>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default ConsentList;
