import PageContainer from 'components/shared/generic/pageContainer/presentational/PageContainer';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import useTranslate from 'lib/languages/useTranslate';
import { MORE } from 'constants/shared/routes';
import { useGetConsentsQuery } from 'lib/services/tellGateway';
import { ConsentScope, ConsentStatus, SubTab } from 'lib/type';
import DataCheck from 'components/shared/generic/dataCheck/presentational/DataCheck';
import Tabs from 'components/shared/generic/tabs/Tabs';
import ConsentList from './components/ConsentList';

const ConsentManagement = () => {
    const translate = useTranslate('consent');
    const translateLoading = useTranslate('loading');
    const { data: consentData, isFetching: isLoadingConsents, error: consentError } = useGetConsentsQuery();

    const error = consentError as { status: number; data: any } | null;
    
    const filterConsentsByScopeAndStatus = (scope: ConsentScope) => {
        return consentData?.filter((consent:any) => {
            const isStatusValid = [ 
                ConsentStatus.AUTHORISED, 
                ConsentStatus.REVOKED,
                ConsentStatus.CONSUMED, 
                ConsentStatus.EXPIRED
            ].includes(consent.ConsentStatus);

            const isScopeMatch = consent.Scope === scope;

            return isStatusValid && isScopeMatch;
        });
    };

    const tabsData = Object.values(ConsentScope).reduce((acc, scope) => {
        acc[scope] = {
            tabTitle: translate(`tabs.${scope.toLowerCase()}`),
            component: ConsentList,
            props: {
                consentData: filterConsentsByScopeAndStatus(scope),
            },
        };
        return acc;
    }, {});

    const subTabs = [SubTab.ACTIVE, SubTab.HISTORY];

    return (
        <PageContainer className="more-account-details-container">
            <SectionTitle title={translate('titleText')} backRoute={MORE} />
            <DataCheck
                data={consentData}
                error={error?.data?.message}
                className="consent-management"
                isLoading={isLoadingConsents}
                loadingMessage={translateLoading('loadingText')}
                noDataMessage={translate('noDataMessage')}
            >
                <Tabs tabsData={tabsData} subTabs={subTabs}/>
            </DataCheck>
        </PageContainer>
    );
};

export default ConsentManagement;
