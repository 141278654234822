import Typography from 'components/shared/generic/typography/presentational/Typography';
import useTranslate from 'lib/languages/useTranslate';
const ConsentPermissions = ({ permissions }) => {
    const translate = useTranslate('consent');

    return (
        <>
            <Typography tag="p" className="consent-permissions-title">
                {translate('permissions')}
            </Typography>
            <div className="consent-details-permissions">
                {permissions?.map(permission => (
                    <Typography key={permission} tag="p">
                        {permission}
                    </Typography>
                ))}
            </div>
        </>
    );
};

export default ConsentPermissions;
