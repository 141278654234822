import PageContainer from 'components/shared/generic/pageContainer/presentational/PageContainer';
import Button from 'components/shared/generic/button/presentational/Button';
import Typography from 'components/shared/generic/typography/presentational/Typography';
import { useLocation, useHistory } from 'react-router-dom';
import useTranslate from 'lib/languages/useTranslate';
import { useDeleteConsentMutation } from 'lib/services/tellGateway';
import DataCheck from 'components/shared/generic/dataCheck/presentational/DataCheck';
import { MORE_OPEN_BANKING_SERVICES } from 'constants/shared/routes';

const ConsentConfirmationDelete = () => {
    const translate = useTranslate('consent');
    const location = useLocation();
    const history = useHistory();

    const [deleteConsent, { isLoading: isLoadingDeleteConsent, error: deleteConsentError }] = useDeleteConsentMutation();

    const error = deleteConsentError as { status: number; data: any } | null;

    const { consent } = location.state || {};

    const handleGoBack = () => {
        history.goBack();
    }

    const handleConsentDelete = async (consentId: string) => {
        try {
            await deleteConsent(consentId).unwrap();
            history.push(MORE_OPEN_BANKING_SERVICES);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <PageContainer className="consent-details-container">
            <DataCheck error={error?.data?.message}>
                <Typography tag="h1">{translate("cancelConsentPermission")}</Typography>
                <Typography tag="p">{translate("cancelConsentPermissionDescription", { tppName: consent?.TppName })}</Typography>
                <Typography className='consent-delete-name' tag="h2">{consent?.TppName}</Typography>
                <div className="consent-delete-content">
                    <Typography tag="p">{translate("cancelConsentPermissionConfirm")}</Typography>
                    <div className="consent-delete-buttons">
                        <Button disabled={isLoadingDeleteConsent} onClick={handleGoBack}>{translate('buttonCancel')}</Button>
                        <Button disabled={isLoadingDeleteConsent} onClick={() => handleConsentDelete(consent?.ConsentId)}>{translate('buttonConfirm')}</Button>
                    </div>
                </div>
            </DataCheck>
        </PageContainer>
    );
};

export default ConsentConfirmationDelete;
